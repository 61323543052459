<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import MapButton from "@/components/Button/MapButton.vue";
import MapContainer from "@/components/MapContainer/MapContainer.vue";

const store = useStore()

onMounted(() => {
  store.commit({ type: 'changeMapTitle', title: 'Mapa Anemias Hemolíticas' })
})
</script>

<template>
  <div class="flex flex-col place-content-start pt-[100px] pb-[100px] px-[100px] h-[2600px]">
    <div class="flex flex-row items-start max-h-[136px]">
      <div class="flex flex-col items-center" style="margin-right: -205px;">
        <MapButton :class="'max-w-[400px] text-xl'"> Anemias Hemolíticas </MapButton>

        <svg style="display: flex; height: 30px;">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <MapContainer :body_class="'text-md text-white'" :class="'max-w-[130px]'" :bg_color="'bg-red-800 hover:bg-red-400 hover:bg-red-400'">
          Definição
        </MapContainer>

        <svg style="display: flex; height: 30px;">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <MapContainer :body_class="'text-md text-left text-red-900'" :class="'max-w-[300px]'">
          Descompensação medular ocorre com destruição de eritrócitos &#8249; 120 dias. Diminuição na eritrometria no
          sangue
          periférico. </MapContainer>

        <svg style="display: flex; height: 30px;">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <MapContainer :body_class="'text-md text-white text-center'" :class="'max-w-[225px]'" :bg_color="'bg-red-800 hover:bg-red-400'">
          Hemólise/ Destruição ocorre
        </MapContainer>

        <svg style="display: flex; height: 30px;">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <MapButton :class="'max-w-[150px] text-md'"> Mecanismo </MapButton>

        <!-- Three child fork -->
        <svg style="display: flex; height: 30px;">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>
        <svg style="height: 50px; margin-top: -50px; display: flex; width: 300px;">
          <line x1="0" y1="50" x2="300" y2="50" stroke="black" />
        </svg>
        <svg style="display: flex; height: 20px;">
          <line x1="0" y1="0" x2="0" y2="20" stroke="black" />
        </svg>
        <svg style="display: flex; height: 20px; margin-top: -20.35px;">
          <line x1="300" y1="0" x2="300" y2="20" stroke="black" />
        </svg>
        <svg style="display: flex; height: 450px; margin-top: -20.35px;">
          <line x1="150" y1="0" x2="150" y2="450" stroke="black" />
        </svg>

        <div class="flex flex-row">
          <div class="flex flex-col items-center" style="margin-right: -250px; margin-top: -435px;">
            <MapContainer :body_class="'text-md text-white text-center h-fit w-fit py-1'" :class="'max-w-[335px]'"
              :bg_color="'bg-red-800 hover:bg-red-400'">
              Extravascular
            </MapContainer>

            <svg style="display: flex; height: 30px;">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>

            <MapContainer :body_class="'text-md text-left text-red-900 pr-9'" :class="'max-w-[250px]'">
              Destruição e remoção dos eritrócitos realizada pelos macrófagos, causadas por: <br>
              <ul>
                <li>- Infecções;</li>
                <li>- Fármacos;</li>
                <li>- Lesões mecânicas</li>
                <li>e outros. </li>
              </ul><br>
              Fator relacionado
              fora dos eritrócitos.
            </MapContainer>
          </div>

          <div class="flex flex-col items-center">
            <MapContainer :body_class="'text-md text-white text-center h-fit py-1'" :class="'max-w-[135px]'"
              :bg_color="'bg-red-800 hover:bg-red-400'">
              Diante dos mecanismos
            </MapContainer>

            <svg style="display: flex; height: 15px;">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>

            <div>
              <img class="max-h-[200px] w-auto ml-1"
                src="@/assets/mapa-de-anemias-hemoliticas/imagens/hemolise-intra-e-extra-muscular.png" alt="">
            </div>

            <svg style="display: flex; height: 20px;">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>

            <MapContainer :body_class="'text-md text-center text-red-900'" :class="'max-w-[230px]'">
              Classificação das Anemias Hemolíticas
            </MapContainer>

            <svg style="display: flex; height: 20px;">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>

            <MapContainer :body_class="'text-md text-white text-center h-fit w-fit py-1'" :class="'max-w-[335px]'"
              :bg_color="'bg-red-800 hover:bg-red-400'">
              Se Apresenta
            </MapContainer>

            <!-- two child fork -->
            <svg style="display: flex; height: 30px;">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>
            <svg style="height: 50px; margin-top: -50px; display: flex; width: 202px;">
              <line x1="0" y1="50" x2="202" y2="50" stroke="black" />
            </svg>
            <svg style="display: flex; height: 20px;">
              <line x1="50" y1="0" x2="50" y2="20" stroke="black" />
            </svg>
            <svg style="display: flex; height: 20px; margin-top: -20.35px;">
              <line x1="251" y1="0" x2="251" y2="20" stroke="black" />
            </svg>

            <div class="flex flex-row space-x-6">
              <MapContainer :body_class="'text-md text-left text-red-900'" :class="'max-w-[260px]'">
                <p class="text-center text-lg mb-2">Adquiridas</p>
                <ul class="mb-5">
                  <li class="font-bold">Autoimunes</li>
                  <li>AHAI por anticorpos quentes</li>
                  <li>AHAI por anticorpos frios</li>
                  <li>AHAI misto</li>
                </ul>

                <ul>
                  <li class="font-bold">Aloimunes</li>
                  <li>- Reação Hemolítica transfusional.</li>
                  <li>- Doença Hemolítica do recém nascido.</li>
                </ul>
              </MapContainer>

              <MapContainer :body_class="'text-md text-left text-red-900 pr-9'" :class="'max-w-[250px]'">
                <p class="text-center text-lg mb-2">Hereditárias</p>
                <ul>
                  <li class="mb-4">- Defeitos na membrana eritricitária</li>
                  <li class="mb-4">- Deficiência de ezimas eritriocitária</li>
                  <li>- Hemoglobinopatias</li>
                </ul>
              </MapContainer>

            </div>
          </div>

          <div class="flex flex-col items-center" style="margin-left: -250px; margin-top: -435px;">
            <MapContainer :body_class="'text-md text-white text-center h-fit w-fit py-1'" :class="'max-w-[335px]'"
              :bg_color="'bg-red-800 hover:bg-red-400'">
              Intravascular
            </MapContainer>

            <svg style="display: flex; height: 30px;">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>

            <MapContainer :body_class="'text-md text-left text-red-900 pr-9'" :class="'max-w-[250px]'">
              Destruição dos eritrócitos na corrente sanguínea, causadas por: <br>
              <ul class="mb-5">
                <li>- Alteração na membrana;</li>
                <li>- Metabolismo ou estrutura das hemácias.</li>
              </ul>
              Fator relacionado dentro dos eritrócitos.
              <ul class="mt-5">
                <li>- Membranopatias;</li>
                <li>- Enzimopatias;</li>
                <li>- Hemoglobinopatias;</li>
                <li>- Inclusões eritrocitárias.</li>
              </ul>
            </MapContainer>
          </div>
        </div>
      </div>

      <svg style="height: 50px; margin-top: -25px; display: flex; width: 300px;">
        <line x1="0" y1="50" x2="575" y2="50" stroke="black" />
      </svg>
      <div>
        <MapButton :class="'max-w-[200px] text-md'"> Hereditárias </MapButton>
      </div>
      <svg style="height: 50px; margin-top: -25px; display: flex; width: 300px;">
        <line x1="0" y1="50" x2="575" y2="50" stroke="black" />
      </svg>

      <div class="flex flex-col items-center" style="margin-left: -425px; margin-right: -1150px;">
        <MapContainer :body_class="'text-xl text-white font-bold'" :class="'max-w-[400px]'" :bg_color="'bg-red-800 hover:bg-red-400'"
          style="margin-left: -600px;">
          Defeitos na membrana eritrocitária
        </MapContainer>

        <svg style="display: flex; height: 50px; margin-left: -600px;">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <div class="flex flex-row items-start -mt-3">
          <div class="flex flex-col" style="margin-right: -625px;">
            <MapContainer :body_class="'text-lg text-white text-center'" :class="'max-w-[400px] w-fit'"
              :bg_color="'bg-red-800 hover:bg-red-400'">
              Esferocitose Hereditária
            </MapContainer>

            <!-- Tree child fork -->
            <svg style="display: flex; height: 30px;">
              <line x1="50" y1="0" x2="50" y2="80" stroke="black" />
            </svg>
            <svg style="height: 50px; margin-top: -50px; display: flex; width: 675px;">
              <line x1="0" y1="50" x2="675" y2="50" stroke="black" />
            </svg>
            <svg style="display: flex; height: 20px;">
              <line x1="0px" y1="0" x2="0px" y2="20" stroke="black" />
            </svg>
            <svg style="display: flex; height: 20px; margin-top: -20.35px;">
              <line x1="350" y1="0" x2="350" y2="200" stroke="black" />
            </svg>
            <svg style="display: flex; height: 20px; margin-top: -20.35px;">
              <line x1="675" y1="0" x2="675" y2="20" stroke="black" />
            </svg>

            <div class="flex flex-row">
              <div class="flex flex-col items-center" style="margin-left: -150px;">
                <MapButton :class="'max-w-[200px] text-md'"> Causa </MapButton>
                <svg style="display: flex; height: 20px;">
                  <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                </svg>
                <MapContainer  :class="'max-w-[275px]'" :title="'Etiologia'">
                  <p class="text-left text-red-900">
                    Mutações ligada ao cromossomo X, genes que codificam proteínas estruturais da membrana das hemácias
                  <ul>
                    <li>- Espectrina</li>
                    <li>- Anquirina</li>
                    <li>- Proteína banda 3</li>
                    <li>- Proteína 4</li>
                  </ul>
                  Causando diminuição ou disfunção dessas proteínas
                  </p>
                </MapContainer>
              </div>

              <div class="flex flex-col items-center" style="margin-left: -125px;">
                <MapButton :class="'max-w-[200px] text-md'"> Apresenta </MapButton>

                <svg style="display: flex; height: 20px;">
                  <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                </svg>

                <MapContainer :class="'max-w-[325px]'" :title="'Fisiopatologia'">
                  <p class="text-left text-red-900">
                  <ul>
                    <li class="mb-3">
                      -Deficiência ou disfunção de proteínas estruturais das membranas dos eritrócitos.
                    </li>
                    <li class="mb-3">
                      - Interações verticais diminuídas;
                    </li>
                    <li class="mb-3">
                      - Perda de membrana microvesiculas (forma) diminuição da superficie em relação ao volume;
                    </li>
                    <li class="mb-3">
                      - Perda de elasticidade;
                    </li>
                    <li class="mb-3">
                      - Presos nos capilares estreitos na circulação aprisionamento esplênico;
                    </li>
                    <li>
                      - Destruição e hemólise
                    </li>
                  </ul>
                  </p>
                </MapContainer>

                <svg style="display: flex; height: 30px;">
                  <line x1="150" y1="0" x2="150" y2="30" stroke="black" />
                </svg>

                <MapButton :class="'max-w-[200px] text-md'"> Para realizar </MapButton>

                <svg style="display: flex; height: 30px;">
                  <line x1="150" y1="0" x2="150" y2="30" stroke="black" />
                </svg>

                <MapContainer :class="'max-w-[325px]'" :title="'Diagnóstico'">
                  <p class="text-left text-red-900">
                  <ul>
                    <li>- História familiar.</li>
                    <li>- Hemograma.</li>
                    <li>- Contagem de Reticulócitos.</li>
                    <li>- Teste de fragilidade osmótica incubada.</li>
                    <li>- Teste Coombs direto.</li>
                    <li>- Eosina-5-maleimida (EMA).</li>
                    <li>- Testes de Hemólise.</li>
                  </ul>
                  </p>
                </MapContainer>

                <!-- two child fork -->
                <svg style="display: flex; height: 30px;">
                  <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
                </svg>
                <svg style="height: 50px; margin-top: -50px; display: flex; width: 400px;">
                  <line x1="50" y1="50" x2="400" y2="50" stroke="black" />
                </svg>
                <svg style="display: flex; height: 20px;">
                  <line x1="0" y1="0" x2="0" y2="20" stroke="black" />
                </svg>
                <svg style="display: flex; height: 20px; margin-top: -20.35px; width: 400px;">
                  <line x1="400" y1="0" x2="400" y2="20" stroke="black" />
                </svg>

                <div class="flex flex-row">
                  <div class="flex flex-col items-center">
                    <MapButton :class="'max-w-[200px] text-md'"> Observamos </MapButton>
                    <svg style="display: flex; height: 20px;">
                      <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                    </svg>
                    <MapContainer :class="'max-w-[325px]'"
                      :title="'Achados laboratoriais'">
                      <p class="text-left text-red-900">
                      <ul>
                        <li>- Depende da classificação clínica.</li>
                        <li>- Anemia silenciosa a grave hemoglobina diminuída de esferócitos e reticulócitos.</li>
                        <li>- VCM variável com aumento de esferócitos e reticulócitos.</li>
                        <li>- RDW normal a elevado.</li>
                        <li>- CHCM elevado nos casos moderados a graves. </li>
                        <li>- Poiquilocitose às custas de esferócitos, estomatócitos e acantócitos. </li>
                        <li>- Policromasia e podem aparecer eritroblastos.</li>
                      </ul>
                      </p>
                    </MapContainer>
                    <svg style="display: flex; height: 50px;">
                      <line x1="150" y1="0" x2="150" y2="50" stroke="black" />
                    </svg>
                    <div class="flex flex-row" style="margin-right: -125px;">
                      <!-- Imagem Microscópio -->
                      <!-- <img class="max-h-[200px] w-auto ml-1" src="@/assets/mapa-de-anemias-hemoliticas/imagens/hemolise-intra-e-extra-muscular.png" alt=""> -->

                      <MapButton :class="'max-w-[125px] text-md h-fit'" style="margin-left: 60px;"> Principal achado
                        lâmina </MapButton>

                      <!-- Vertical three child fork -->
                      <svg style="height: 50px; display: flex; width: 25px;">
                        <line x1="0" y1="50" x2="25" y2="50" stroke="black" />
                      </svg>
                      <svg style="display: flex; height: 105px; width: 1px;">
                        <line x1="0" y1="0" x2="0" y2="105" stroke="black" />
                      </svg>
                      <svg style="height: 50px; display: flex; width: 25px; margin-left: -1px;">
                        <line x1="0" y1="0" x2="25" y2="0" stroke="black" />
                      </svg>
                      <svg style="display: flex; width: 25px; height: 60px; margin-left: -25px;">
                        <line x1="0" y1="50" x2="25" y2="50" stroke="black" />
                      </svg>
                      <svg style="display: flex; width: 25px; height: 105px; margin-left: -25px;">
                        <line x1="0" y1="105" x2="25" y2="105" stroke="black" />
                      </svg>


                      <div class="flex flex-col">
                        <MapContainer :body_class="'text-sm text-white py-1 h-fit'" :class="'max-w-[400px] -mt-4 mb-5'"
                          :bg_color="'bg-red-800 hover:bg-red-400'">
                          Esferócitos
                        </MapContainer>

                        <MapContainer :body_class="'text-sm text-white py-1 h-fit'" :class="'max-w-[400px] mb-6 -mt-1'"
                          :bg_color="'bg-red-800 hover:bg-red-400'">
                          Acantócitos
                        </MapContainer>

                        <MapContainer :body_class="'text-sm text-white py-1 h-fit'" :class="'max-w-[400px]'"
                          :bg_color="'bg-red-800 hover:bg-red-400'">
                          Estomatócitos
                        </MapContainer>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col items-center" style="margin-left: 25px;">
                    <MapButton :class="'max-w-[200px] text-md'"> Orientação médica </MapButton>
                    <svg style="display: flex; height: 20px;">
                      <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                    </svg>
                    <MapContainer  :class="'max-w-[325px]'" :title="'Tratamento'">
                      <p class="text-left text-red-900">
                      <ul>
                        <li>- Uso de àcido fólico;</li>
                        <li>- Esplenectomia;</li>
                        <li>- Transfusões sanguíneas casos graves.</li>
                      </ul>
                      </p>
                    </MapContainer>
                  </div>
                </div>
              </div>

              <div class="flex flex-col items-center" style="margin-left: -150px;">
                <MapButton :class="'max-w-[200px] text-md'"> Principais </MapButton>
                <svg style="display: flex; height: 20px;">
                  <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                </svg>
                <MapContainer  :class="'max-w-[250px]'" :title="'Achados clínicos'">
                  <p class="text-left text-red-900">
                    <b class="mb-2 font-bold">Sintomático</b>
                  <ul>
                    <li>
                      - Palidez
                    </li>
                    <li>
                      - Ictericia Litíase vesicular
                    </li>
                    <li>
                      - Esplenomegalia ou assintomático
                    </li>
                  </ul>
                  </p>
                </MapContainer>
              </div>
            </div>
          </div>


          <svg style="height: 50px; margin-top: -30px; display: flex; width: 300px;">
            <line x1="0" y1="50" x2="300" y2="50" stroke="black" />
          </svg>

          <MapButton :class="'max-w-[200px] text-md h-fit'"> Principais alterações </MapButton>

          <svg style="height: 50px; margin-top: -30px; display: flex; width: 300px;">
            <line x1="0" y1="50" x2="300" y2="50" stroke="black" />
          </svg>

          <div class="flex flex-col">
            <MapContainer :body_class="'text-lg text-white'" :class="'max-w-[400px] w-fit'" :bg_color="'bg-red-800 hover:bg-red-400'">
              Eliptocitose Hereditária
            </MapContainer>

            <!-- Tree child fork -->
            <svg style="display: flex; height: 30px;">
              <line x1="50" y1="0" x2="50" y2="80" stroke="black" />
            </svg>
            <svg style="height: 50px; margin-top: -50px; display: flex; width: 675px;">
              <line x1="0" y1="50" x2="675" y2="50" stroke="black" />
            </svg>
            <svg style="display: flex; height: 20px;">
              <line x1="0px" y1="0" x2="0px" y2="20" stroke="black" />
            </svg>
            <svg style="display: flex; height: 20px; margin-top: -20.35px;">
              <line x1="350" y1="0" x2="350" y2="200" stroke="black" />
            </svg>
            <svg style="display: flex; height: 20px; margin-top: -20.35px;">
              <line x1="675" y1="0" x2="675" y2="20" stroke="black" />
            </svg>

            <div class="flex flex-row">
              <div class="flex flex-col items-center" style="margin-left: -150px;">
                <MapButton :class="'max-w-[200px] text-md'"> Causa </MapButton>
                <svg style="display: flex; height: 20px;">
                  <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                </svg>
                <MapContainer :body_class="'text-red-900'" :class="'max-w-[275px]'" :title="'Etiologia'">
                  <!-- Etiologia -->
                  <p class="mb-2">
                    Mutações nos genes estruturais das hemácias
                  </p>

                  <ul class="mb-2">
                    <li> a) Alfa-Espectrina </li>
                    <li> b) Beta-Espectrina </li>
                    <li> c) Glicoforina C e outras</li>
                  </ul>

                  <p>
                    Causando defeitos no heterodímetos não se ligam os heterotetrâmetos
                  </p>
                </MapContainer>
              </div>

              <div class="flex flex-col items-center" style="margin-left: -245px;">
                <MapButton :class="'max-w-[200px] text-md'"> Apresenta </MapButton>

                <svg style="display: flex; height: 20px;">
                  <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                </svg>

                <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'"
                  :title="'Fisiopatologia'">
                  <!-- Fisiopatologia -->
                  <ul>
                    <li class="mb-1">- Mutações nas proteínas estruturais</li>
                    <li class="mb-1">- Defeitos nos heterodímeros, não assosciação aos heterotetrâmeros</li>
                    <li class="mb-1">- Interações horizontais</li>
                    <li class="mb-1">- Instabilidade no citoesqueleto dos eritrócitos (eliptócitos).</li>
                    <li class="mb-1">- Fragmentação celular e hemólise.</li>
                  </ul>
                </MapContainer>

                <svg style="display: flex; height: 30px;">
                  <line x1="150" y1="0" x2="150" y2="30" stroke="black" />
                </svg>

                <MapButton :class="'max-w-[200px] text-md'"> Para realizar </MapButton>

                <svg style="display: flex; height: 30px;">
                  <line x1="150" y1="0" x2="150" y2="30" stroke="black" />
                </svg>

                <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'" :title="'Diagnóstico'">
                  <!-- Diagnostico -->
                  <ul>
                    <li>- História familiar.</li>
                    <li>- Hemograma.</li>
                    <li>- Contagem de Reticulócitos.</li>
                    <li>- Curva de fragilidade osmótica (alterado casos graves)</li>
                    <li>- Coombs direto.</li>
                    <li>- Eletroforese de proteínas</li>
                    <li>- Testes adicionais, Análise gênica ou molecular (quantiticação de proteínas da membrana
                      eritrocitária)</li>
                  </ul>
                </MapContainer>

                <!-- two child fork -->
                <svg style="display: flex; height: 30px;">
                  <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
                </svg>
                <svg style="height: 50px; margin-top: -50px; display: flex; width: 600px;">
                  <line x1="150" y1="50" x2="600" y2="50" stroke="black" />
                </svg>
                <svg style="display: flex; height: 20px;">
                  <line x1="0" y1="0" x2="0" y2="20" stroke="black" />
                </svg>
                <svg style="display: flex; height: 20px; margin-top: -20.35px; width: 600px;">
                  <line x1="600" y1="0" x2="600" y2="20" stroke="black" />
                </svg>

                <div class="flex flex-row" style="margin-left: 150px;">
                  <div class="flex flex-col items-center" style="margin-right: 100px;">
                    <MapButton :class="'max-w-[200px] text-md'"> Observamos </MapButton>
                    <svg style="display: flex; height: 20px;">
                      <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                    </svg>
                    <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'"
                      :title="'Achados laboratoriais'">
                      <!-- Achados Laboratoriais -->
                      <ul>
                        <li>- Geralmente são assintomáticos.</li>
                        <li>- Sintomáticos depende da classificação clínica.</li>
                        <li>- Hemoglobina normal a diminuído.</li>
                        <li>- VCM normal a diminuído.</li>
                        <li>- RDW normal nos casos brandos e elevado nos graves ex: piropoiquilo citose.</li>
                        <li>- Poiquiocitose às custas de Eliptócitos, codócitos, esquizócitos microesferócitos,
                          microeliptócitos e piropoiquilócitos.</li>
                        <li>- Reticulócitos podem variar de acordo com a classificação clínica.</li>
                      </ul>
                    </MapContainer>
                    <svg style="display: flex; height: 40px;">
                      <line x1="150" y1="0" x2="150" y2="40" stroke="black" />
                    </svg>
                    <div class="flex flex-row" style="margin-right: -550px; margin-top: -15px;">
                      <div class="flex flex-col">
                        <div class="flex flex-row items-center">
                          <img class="max-h-[200px] w-auto ml-1"
                            src="@/assets/mapa-de-anemias-hemoliticas/imagens/microscopio.png" alt="">

                          <svg style="display: flex; width: 25px;">
                            <line x1="0" y1="75" x2="25" y2="75" stroke="black" />
                          </svg>

                          <img class="max-h-[200px] w-auto ml-1"
                            src="@/assets/mapa-de-anemias-hemoliticas/imagens/lamina-poiquilocitose.png" alt="">

                          <svg style="display: flex; width: 25px;">
                            <line x1="0" y1="75" x2="25" y2="75" stroke="black" />
                          </svg>

                          <MapButton :class="'max-w-[125px] text-md h-fit'"> Principal
                            alteração nas hemácias lâmina </MapButton>

                          <svg style="display: flex; width: 40px;">
                            <line x1="0" y1="75" x2="40" y2="75" stroke="black" />
                          </svg>
                          <MapContainer :body_class="'px-0 mx-0 py-0 my-0'" :title="'Eliptócitos'">
                          <img class="max-h-[200px] w-auto ml-1"
                            src="@/assets/mapa-de-anemias-hemoliticas/imagens/hereditary-elliptocytosis.jpg" alt="">
                          </MapContainer>
                        </div>
                        <svg style="display: flex; height: 125px; margin-top: -15px;">
                          <line x1="275" y1="0" x2="275" y2="125" stroke="black" />
                        </svg>
                        <div class="flex flex-row items-center" style="margin-left: 200px; margin-top: -95px;">
                          <MapContainer :body_class="'text-md text-white text-center'" :class="'max-w-[200px]'"
                            :bg_color="'bg-red-800 hover:bg-red-400'">
                            Poiquilocitose
                          </MapContainer>

                          <svg style="display: flex; width: 25px;">
                            <line x1="0" y1="75" x2="25" y2="75" stroke="black" />
                          </svg>

                          <MapContainer :body_class="'px-0 mx-0 py-0 my-0'" :title="'Esquizócitos'">
                            <img class="max-h-[200px] w-min"
                              src="@/assets/mapa-de-anemias-hemoliticas/imagens/esquizocito.png"
                              alt="">
                          </MapContainer>
                          <MapContainer :body_class="'px-0 mx-0 py-0 my-0 h-[200px]'" :title="'Codócitos'">
                          <img class="max-h-[200px] w-min"
                              src="@/assets/mapa-de-anemias-hemoliticas/imagens/target-cells-peripheral-blood-smear.jpg"
                              alt="">
                          </MapContainer>


                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col items-center">
                    <MapButton :class="'max-w-[200px] text-md'"> Orientação médica </MapButton>
                    <svg style="display: flex; height: 20px;">
                      <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                    </svg>
                    <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'"
                      :title="'Tratamento'">
                      <!-- Tratamento -->
                      <p>
                        Geralmente não se faz necessário uma vez que não há manifestações clínicas.
                      </p>
                      <p>
                        Em casos graves:

                      <ul>
                        <li>- Esplenectomia;</li>
                        <li>- Transfusões sanguíneas casos graves;</li>
                        <li>- Uso de àcido fólico.</li>
                      </ul>
                      </p>
                    </MapContainer>
                  </div>
                </div>
              </div>

              <div class="flex flex-col items-center" style="margin-left: -275px;">
                <MapButton :class="'max-w-[200px] text-md'"> Principais </MapButton>
                <svg style="display: flex; height: 20px;">
                  <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                </svg>
                <MapContainer :body_class="' text-red-900'" :class="'max-w-[250px]'"
                  :title="'Achados clínicos'">
                  <!-- Achados Clinicos -->
                  <p class="mb-3">
                    Assintomático forma heterozigotas
                  </p>

                  <ul>
                    <li>- Sintomática</li>
                    <li>- Homozigotos ou dupla heterozigose</li>
                    <li>- Fadiga e dispnéia</li>
                    <li>- Esplenomegalia</li>
                    <li>- Dor abdominal</li>
                    <li>- Cálculos bilares</li>
                  </ul>
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <svg style="height: 50px; margin-top: -25px; display: flex; width: 1900px;">
        <line x1="0" y1="50" x2="1900" y2="50" stroke="black" />
      </svg>

      <div class="flex flex-col items-center" style="margin-left: -400px;">
        <MapContainer :body_class="'text-xl text-white font-bold'" :class="'max-w-[400px]'" :bg_color="'bg-red-800 hover:bg-red-400'"
          style="margin-left: -1200px;">
          Deficiência de enzima Eritrocitária
        </MapContainer>

        <svg style="display: flex; height: 30px; margin-left: -1200px;">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>

        <MapContainer :body_class="'text-lg text-white text-center'" :class="'max-w-[335px]'" :bg_color="'bg-red-800 hover:bg-red-400'"
          style="margin-left: -1200px;">
          Deficiência de enzimas eritrocitária G6PD e Piruvato Cinase
        </MapContainer>

        <!-- two child fork -->
        <svg style="display: flex; height: 30px; margin-left: -1200px;">
          <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
        </svg>
        <svg style="height: 50px; margin-top: -50px; display: flex; width: 202px; margin-left: -1200px;">
          <line x1="0" y1="50" x2="202" y2="50" stroke="black" />
        </svg>
        <svg style="display: flex; height: 20px; width: 202px; margin-left: -1200px;">
          <line x1="0" y1="0" x2="0" y2="20" stroke="black" />
        </svg>
        <svg style="display: flex; height: 20px; margin-top: -20.35px; margin-left: -1200px;">
          <line x1="251" y1="0" x2="251" y2="20" stroke="black" />
        </svg>

        <div class="flex flex-row" style="margin-left: -350px;">
          <div class="flex flex-col items-center">
            <MapButton :class="'max-w-[200px] text-md h-fit w-fit'" style="margin-left: 250px;"> Causa </MapButton>
            <svg style="display: flex; height: 30px; margin-left: 250px;">
              <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
            </svg>
            <MapContainer :body_class="'text-sm text-white py-1 h-fit w-fit'" :class="'max-w-[400px] -mt-4'"
              :bg_color="'bg-red-800 hover:bg-red-400'" style="margin-left: 250px;">
              Etiologia
            </MapContainer>

            <!-- two child fork -->
            <svg style="display: flex; height: 30px; width: 350px;">
              <line x1="300" y1="0" x2="300" y2="80" stroke="black" />
            </svg>
            <svg style="height: 50px; margin-top: -50px; display: flex; width: 350px;">
              <line x1="0" y1="50" x2="350" y2="50" stroke="black" />
            </svg>
            <svg style="display: flex; height: 30px; width: 350px;">
              <line x1="0" y1="0" x2="0" y2="30" stroke="black" />
            </svg>
            <svg style="display: flex; height: 30px; margin-top: -30.35px; width: 350px;">
              <line x1="350" y1="0" x2="350" y2="30" stroke="black" />
            </svg>

            <div class="flex flex-row space-x-8">
              <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'" :title="'G6PD'">
               <ul>
                <li>- Disturbio hereditário recessivo ligada ao cromossomo X.</li>
                <li>- Comum em homens.</li>
                <li>- Enzima mutante atividade reduzida ou meia vida reduzida.</li>
                <li>- Processo infeccioso</li>
                <li>- Drogas oxidantes</li>
                <li>- Suceptibilidade a estresse oxidativo resultando em hemólise.</li>
               </ul>
              </MapContainer>
              <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'"
                :title="'Piruvato Cinase'">
                <ul>
                <li>- Disturbio hereditário autossómico recessivo, causada por mutações no gene PKLR que codifica enzima piruvato cinase(PK).</li>
                <li>- Enzima responsável pela produção de ATP no eritrócito.</li>
                <li>- Desequilibrio nos niveis de ATP (Eritropoese irregular).</li>
                <li>- Redução da sobrevida dos eritrócitos, hemólise.</li>
               </ul>
              </MapContainer>
            </div>
          </div>

          <div class="flex flex-row" style="margin-left: -50px;">
            <MapButton :class="'max-w-[200px] text-md h-fit'"> Apresenta </MapButton>

            <svg style="height: 50px; margin-top: -25px; display: flex; width: 250px;">
              <line x1="0" y1="50" x2="250" y2="50" stroke="black" />
            </svg>

            <div class="flex flex-col items-center" style="margin-left: -870px; margin-top: 8px;">
              <MapContainer :body_class="'text-md text-white text-center h-fit py-1 w-fit'" :class="'max-w-[335px]'"
                :bg_color="'bg-red-800 hover:bg-red-400'">
                Fisiopatologia
              </MapContainer>
              <!-- Three child fork -->
              <svg style="display: flex; height: 30px;">
                <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
              </svg>
              <svg style="height: 50px; margin-top: -50px; display: flex; width: 950px; margin-left: 600px;">
                <line x1="0" y1="50" x2="950" y2="50" stroke="black" />
              </svg>
              <svg style="display: flex; height: 40px; width: 950px; margin-left: 600px;">
                <line x1="0" y1="0" x2="0" y2="40" stroke="black" />
              </svg>
              <svg style="display: flex; height: 40px; margin-top: -40.35px; margin-left: 600px;">
                <line x1="150" y1="0" x2="150" y2="40" stroke="black" />
              </svg>
              <svg style="display: flex; height: 40px; margin-top: -40.35px; width: 950px; margin-left: 600px;">
                <line x1="950" y1="0" x2="950" y2="40" stroke="black" />
              </svg>

              <div class="flex flex-row" style="margin-left: 600px;">
                <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'" :title="'G6PD'">
                  <ul>
                    <li>- Deficiência da G6PD</li>
                    <li>- Diminuição de NADP e NADH</li>
                    <li>- Diminuição potencial redutor</li>
                    <li>- Inteferência na metabolização oxidativa</li>
                    <li>- Vunerabilidade a hemólise, grupos sulfidrilas desprotegido</li>
                    <li>- Oxidação da membrana das hemácias.</li>
                  </ul>
                </MapContainer>

                <div class="flex flex-col items-center">
                  <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'">
                    Via glicolítica de Embden-Meyerhof e suas interações com a via da pentose fosfato e com o shunt
                    Luebering-Rapoport. Adaptado de Hoffbrand AV et.al, 2010
                  </MapContainer>
                  <svg style="display: flex; height: 100px;">
                    <line x1="125" y1="0" x2="125" y2="100" stroke="black" />
                  </svg>
                  <div class="flex flex-row" style="margin-left: -700px;">
                    <div class="flex flex-col items-center" style="margin-right: -200px; margin-top: 100px;">
                      <MapContainer :body_class="' text-red-900'" :class="'max-w-[350px]'"
                        :title="'Diagnóstico'">
                        <p class="text-lg mb-2">Deficiência Enzimática</p>
                        <ul>
                          <li>- Graus variáveis de anemia.</li>
                          <li>- Hemograma.</li>
                          <li>- Exames bioquimicos: LDH, AST, Bilirrubina (aumentada),haptoglobina (diminuição).</li>
                          <li>- Contagem de reticulocitos (aumentados).</li>
                          <li>- Dosagem da atividade da G6PD e Cinase Piruvato.</li>
                          <li>- Caracterização molecular.</li>
                          <li>- Azul de cresil Brilhante G6PD (corpos de Heinz).</li>
                          <li>- G6PD Teste de Brewer: Positivo</li>
                        </ul>
                      </MapContainer>
                      <!-- two child fork -->
                      <svg style="display: flex; height: 30px;">
                        <line x1="150" y1="0" x2="150" y2="80" stroke="black" />
                      </svg>
                      <svg style="height: 50px; margin-top: -50px; display: flex; width: 375px;">
                        <line x1="0" y1="50" x2="375" y2="50" stroke="black" />
                      </svg>
                      <svg style="display: flex; height: 30px; width: 375px;">
                        <line x1="0" y1="0" x2="0" y2="30" stroke="black" />
                      </svg>
                      <svg style="display: flex; height: 30px; width: 375px; margin-top: -30.35px;">
                        <line x1="375" y1="0" x2="375" y2="30" stroke="black" />
                      </svg>
                      <div class="flex flex-row items-start">
                        <div class="flex flex-col items-center">
                          <MapButton :class="'max-w-[200px] text-md h-fit w-fit'"> Observamos </MapButton>
                          <svg style="display: flex; height: 20px;">
                            <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                          </svg>
                          <MapContainer :body_class="' text-red-900'" :class="'max-w-[350px]'"
                            :title="'Achados laboratoriais'">

                            <ul>
                              <li>- Hemograma;</li>
                              <li>- Anemia normocítica normocrônica;</li>
                              <li>- Policromasia, anisocitose;</li>
                              <li>- Poiquilocitose: queratócito esquizócitos;</li>
                              <li>- Hemácias em bolhas (Blister cell) corpos de Heinz (G6PD);</li>
                              <li>- Leucocitose; Neutrofilia.</li>
                            </ul>
                          </MapContainer>
                        </div>
                        <div class="flex flex-col items-center ml-10">
                          <MapButton :class="'max-w-[200px] text-md h-fit w-fit'"> Orientação médica </MapButton>
                          <svg style="display: flex; height: 20px;">
                            <line x1="150" y1="0" x2="150" y2="20" stroke="black" />
                          </svg>
                          <MapContainer :body_class="' text-red-900'" :class="'max-w-[350px]'"
                            :title="'Tratamento'">
                            <p>Não há tratamento específico.</p>
                            <ul>
                              <li>- Hemotransfusão (casos graves);</li>
                              <li>- Esplenectomia;</li>
                              <li>- Orientação alimentar (G6PD) e fármacos;</li>
                              <li>- Podem desenvolver sobrecarga de ferro necessitando de tratamento adequado</li>
                            </ul>
                          </MapContainer>
                        </div>
                      </div>
                    </div>

                    <svg style="height: 50px; margin-top: 125px; display: flex; width: 50px;">
                      <line x1="0" y1="50" x2="50" y2="50" stroke="black" />
                    </svg>
                    <MapButton :class="'max-w-[200px] text-md h-fit'" style="margin-top: 150px;"> Para realizar
                    </MapButton>
                    <svg style="height: 50px; margin-top: 125px; display: flex; width: 50px;">
                      <line x1="0" y1="50" x2="50" y2="50" stroke="black" />
                    </svg>

                    <div class="flex flex-col">
                      <!-- img via glicolitica -->
                      <img class="h-fit max-w-[500px] ml-1"
                        src="@/assets/mapa-de-anemias-hemoliticas/imagens/via-glicolitica.png" alt="">
                      <svg style="display: flex; height: 150px;">
                        <line x1="125" y1="0" x2="125" y2="150" stroke="black" />
                      </svg>
                      <div class="flex flex-row items-center" style="margin-left: 75px; margin-top: -125px;">
                        <MapButton :class="'max-w-[200px] text-md h-fit'"> Principais </MapButton>
                        <svg style="height: 50px; margin-top: -50px; display: flex; width: 75px;">
                          <line x1="0" y1="50" x2="75" y2="50" stroke="black" />
                        </svg>
                        <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'"
                          :title="'Achados clínicos'">
                          <ul>
                            <li>- Fadiga;</li>
                            <li>- Palidez;</li>
                            <li>- Ictericia (neonatal e crônica);</li>
                            <li>- Hemoglobinuria;</li>
                            <li>- Febre;</li>
                            <li>- Dor abdominal (casos graves);</li>
                            <li>- Grau variável de hemolise crônica;</li>
                            <li>- Esplenomegalia e cálculos biliares.</li>
                          </ul>
                        </MapContainer>
                      </div>
                    </div>
                  </div>


                </div>

                <MapContainer :body_class="' text-red-900'" :class="'max-w-[325px]'"
                  :title="'Piruvato Cinase'">
                  <ul>
                    <li>- Deficiência da Piruvato cinase enzima chave da via glicolitica;</li>
                    <li>- Enzima que atua a última etapa da via glicolitica e promove reação irreversivel e
                      indispensável para produção de energia na célula;</li>
                    <li>- Ausência enzimática causa depleção de ATP nos eritrócitos;</li>
                    <li>- Meia vida das células diminuídas, integridade e funcionamento celular comprometido.</li>
                  </ul>
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
